@mixin generic-hover-before {
  content: '';
  top: 0;
  bottom: 0;
  opacity: 0.1;
  filter: blur(12px);
  box-shadow: 0 0 10px theme('colors.pink.500'),
    0 0 40px theme('colors.pink.500');
  @apply bg-pink-500;
  @apply absolute;
  @apply top-0;
  @apply right-0;
  @apply bottom-0;
  @apply left-0;
}
