/* stylelint-disable scss/at-rule-no-unknown */

.form-group {
  @apply mb-1;

  label {
    @apply block;
  }
}

.form-control {
  outline: none;
  @apply min-w-full;
  @apply rounded-none;
  @apply bg-none;
  @apply text-base;
  @apply bg-pink-1200;
  @apply pl-3;
  @apply pr-6;
  @apply py-1;

  & + .required-sign {
    @apply text-pink-500;
    @apply absolute;
    @apply right-2;
  }
}

@screen md {
  .form-group {
    @apply flex;

    label {
      width: 200px;
      @apply text-right;
      @apply mr-3;
    }
  }
}
