/* stylelint-disable scss/at-rule-no-unknown */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './mixins/generic-hover-before.mixin';
@import './app/form/form';

$font-weight-light: 300;
$font-weight-semi-bold: 600;
$transition-time: 150ms;

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Fira Sans', sans-serif;
  font-weight: $font-weight-light;
  @apply text-white-600 text-lg leading-relaxed;
}

header {
  nav {
    @apply hidden;
  }

  nav,
  .main-background {
    @apply top-0 right-0 fixed;
  }
}

h1,
h2 {
  @apply text-white-500 text-4xl leading-tight;
}

h2 {
  font-weight: $font-weight-semi-bold;
  @apply relative px-3 pt-12 mb-16 mx-auto max-w-[23rem];

  &::after {
    $line-width: 80px;

    content: '';
    bottom: -20px;
    left: calc(50% - $line-width / 2);
    width: $line-width;
    height: 1px;
    @apply bg-pink-900 absolute;
  }

  &.reduced-pt {
    @apply pt-10;
  }
}

.sub-intro {
  @apply mb-14;
}

p {
  @apply mb-5;
}

strong {
  font-weight: $font-weight-semi-bold;
}

small {
  @apply text-sm;
}

::selection {
  @apply text-white-600 bg-pink-900;
}

a {
  @apply text-cyan-500;

  &:hover {
    @apply text-pink-500;
  }

  &:active {
    @apply text-pink-100;
  }
}

button {
  line-height: 1.25;
  border-radius: 8px;
  padding: 14px 15px;
  border: 1px solid theme('colors.cyan.500');

  @apply text-cyan-500 relative text-center text-sm;

  &:hover {
    border-color: theme('colors.pink.500');
    @apply text-pink-500;

    &::before {
      @include generic-hover-before;
    }
  }

  &:active {
    border-color: theme('colors.pink.100');
  }

  &.plain {
    line-height: 1;
    @apply p-0 border-none;
  }
}

footer {
  border-top: 1px solid theme('colors.pink.900');
  max-width: 1024px;
  margin: 100px auto 0;
  padding: 16px 30px 0;
  @apply text-base;

  small {
    @apply block;
  }
}

@screen md {
  body {
    @apply text-lg;
  }

  h2 {
    @apply pt-20 max-w-none;

    &.reduced-pt {
      @apply pt-16;
    }
  }

  button {
    @apply text-lg;
  }
}

@screen lg {
  body {
    @apply text-xl;
  }

  h1,
  h2 {
    @apply text-5xl;
  }

  h2 {
    @apply pt-28;

    &::after {
      $line-width: 0;

      width: $line-width;
      left: 50%;
      transform: translateX(-50%);
      bottom: -25px;
    }

    &.animate {
      &::after {
        $line-width: 80px;

        width: $line-width;
        transition: all 0.5s;
      }
    }

    &.reduced-pt {
      @apply pt-20;
    }
  }

  .sub-intro {
    @apply mb-20;
  }

  a {
    transition: color $transition-time;
  }

  button {
    transition: color $transition-time, border-color $transition-time;
  }
}

@screen xl {
  .sub-intro {
    @apply mb-20;
  }

  h2 {
    @apply pt-44;

    &.reduced-pt {
      @apply pt-36;
    }
  }
}
